import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../redux'
import { GroupAddRequest, GroupEditRequest, GroupEditResponse, GroupResponse, GroupsResponse, StudentAddRequest, StudentEditRequest, StudentResponse, StudentsResponse } from '../../types/studentsTypes'
import { EventAddRequest, EventEditRequest, EventResponse, EventsRequest, EventsResponse } from '../../types/eventsTypes'



export const apiEvents = createApi({
  reducerPath: 'apiEvents',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://learnzone.ru/api_lz/',
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    eventAdd: builder.mutation<EventResponse, EventAddRequest>({
      query: (credentials) => ({
        url: 'event/add',
        method: 'POST',
        body: credentials,
      }),
    }),
    eventEdit: builder.mutation<EventResponse, EventEditRequest>({
      query: (credentials) => ({
        url: 'event/edit',
        method: 'PUT',
        body: credentials,
      }),
    }),
    getEvents: builder.query<EventsResponse, EventsRequest>({
      query: (filter) => {
        let searchParams = ''
        searchParams = filter.startDate && filter.endDate ? `?start=${(new Date(filter.startDate))}&end=${(new Date(filter.endDate))}` : ''
        return 'events' + searchParams
      },
    }),

  }),
})

export const { useGetEventsQuery, useEventAddMutation, useEventEditMutation } = apiEvents
