import React from 'react';
import { Typography } from 'antd';


const { Title } = Typography;
const Students: React.FC = () => {

    return (<div>
      <Title level={2}>Пользователи</Title>
  </div>
)};

export default Students;