import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { DateSelectArg, DatesSetArg, EventApi, EventChangeArg, EventClickArg, EventContentArg } from "@fullcalendar/core"
import ruLocale from '@fullcalendar/core/locales/ru';
import { eventEditOnList, selectEvents } from "../../redux/eventsSlice"
import { useDispatch, useSelector } from "react-redux"
import useGetEvents from "../../api/hooks/useGetEvents"
import { useMemo, useState } from "react"
import { EventImpl } from "@fullcalendar/core/internal"
import { useEventEditMutation } from "../../api/services/events"
import useGetStudents from "../../api/hooks/useGetStudents"
import useGetGroups from "../../api/hooks/useGetGroups"
import { selectGroups, selectStudents } from "../../redux/studentsSlice"
import useWindowDimensions from "../../hooks/windowDimentions"

interface ICalendarProps{
  openAddForm: (selectInfo: DateSelectArg,) => void
  openEditForm: (event: EventImpl) => void

}

const Calendar: React.FC<ICalendarProps> = ({openEditForm, openAddForm}) => {
    const events = useSelector(selectEvents)
    const [filter, setFilter] = useState<{startDate?: string,
      endDate?: string}>({startDate: undefined, endDate: undefined})
    const isLoading = useGetEvents(filter);
    const isLoadingSTUDENTS = useGetStudents();
    const isLoadingGroups = useGetGroups()
    const { height } = useWindowDimensions()

    const groups = useSelector(selectGroups)
    const students = useSelector(selectStudents)
    const [eventEdit, resultEdit] = useEventEditMutation()
    const dispatch = useDispatch()
    const handleDateSelect = (selectInfo: DateSelectArg) => {
      console.log(selectInfo)
        // let title = prompt('Please enter a new title for your event')
        // console.log(title)
        openAddForm(selectInfo)
        // let calendarApi = selectInfo.view.calendar
    
        // calendarApi.unselect() // clear date selection
    
        // if (title) {
        //   calendarApi.addEvent({
        //     id: createEventId(),
        //     title,
        //     start: selectInfo.startStr,
        //     end: selectInfo.endStr,
        //     allDay: selectInfo.allDay
        //   })
        // }
      }

    
    const handleEventClick = (clickInfo: EventClickArg) => {
        console.log('event click', clickInfo.event)
        openEditForm(clickInfo.event)
        // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        //   clickInfo.event.remove()
        // }
      }
    
    const handleEventChange = async ({event}: EventChangeArg) => {
        console.log(event)
        const result = await eventEdit({ 
          _id: event.extendedProps._id, 
          title: event.title,
          start: event.startStr,
          end: event.endStr,
        }).unwrap()
        dispatch(eventEditOnList(result.event))
      }
      const handleDates = async (rangeInfo: DatesSetArg) => {
        if(rangeInfo.startStr && rangeInfo.endStr)
          setFilter({startDate: rangeInfo.startStr, endDate: rangeInfo.endStr})
      }
    function renderEventContent(eventContent: EventContentArg) {
      const {event} = eventContent
      let name = ''
      
      if(event.groupId){
        name = groups.find(item => item._id === event.groupId)?.name || ''
      }else if (event.extendedProps.studentId)
        name = students.find(item => item._id === event.extendedProps.studentId)?.name || ''
      return (
        <>
          <div><b>{eventContent.timeText}</b> {name}</div>
          <div>{event.extendedProps.price + '₽' ?? ''}</div>
        </>
      )
    }
    return <FullCalendar
    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
    headerToolbar={{
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    }}
    initialView='timeGridWeek'
    views= {{
        dayGridMonth: { // name of view
          titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' }
          // other view-specific options here
        }
      }}
    editable={true}
    selectable={true}
    selectMirror={true}
    dayMaxEvents={true}
    allDaySlot={false}
    weekends={true}
    locale={ruLocale}
    events={events}
    select={handleDateSelect}
    datesSet={handleDates}
    longPressDelay={300}
    height={height - 152}
    eventContent={renderEventContent} // custom render function
    eventClick={handleEventClick}
    eventChange={handleEventChange}
    /* you can update a remote database when these fire:
    eventAdd={function(){}}
    eventChange={function(){}}
    eventRemove={function(){}}
    */
  />
}

export default Calendar;