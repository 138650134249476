import React, { useEffect } from 'react';
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cleanCredentials, selectCurrentUser, setToken, setUserProfile } from '../../redux/authSlice';
import Cookies from 'js-cookie';
import { errorHandler, FetchError } from '../../utils/errorHandler';
import { useGetCurrentUserQuery } from '../../api/services/auth';
import useGetCurrentUser from '../../api/hooks/useGetCurrentUser';

const UserMenu: React.FC = () => {
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const isLoading = useGetCurrentUser()
    const dispatch = useDispatch();

    const logOut = () => {
        console.log('logout')
        dispatch(cleanCredentials())
        navigate("/login");
    }
    const items: MenuProps['items'] = [

        {
          label: <a onClick={logOut}><LogoutOutlined /> Выйти</a>,
          key: '1',
        },
        // {
        //   type: 'divider',
        // },
      ];

  return <Dropdown menu={{ items }} trigger={['click']}>
    <a onClick={(e) => e.preventDefault()}>
      <Space>
        { user?.surname ? user?.surname + ' ' + user?.name : user?.name }
        <UserOutlined />
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
};

export default UserMenu;
