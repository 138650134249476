import React, { useState } from 'react';
import { Typography } from 'antd';
import Calendar from './calendar';
import EventModalForm from './eventModal';
import { useSelector } from 'react-redux';
import { selectEvents } from '../../redux/eventsSlice';
import { Event, SelectedEvent } from '../../types/eventsTypes';
import { DateSelectArg, EventClickArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import dayjs from 'dayjs';
import Profit from './profit';

const defaultEventValues = {
  title: '',
  start: dayjs(),
  repetitorId: '',
  end: null,
  durations: '',
  _id: '',
}
const { Title } = Typography;
const Schedule: React.FC = () => {
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent>(defaultEventValues)
  const [open, setOpen] = useState<boolean>(false)

  const openAddForm = (selectInfo: DateSelectArg) => {
    setSelectedEvent({ ...defaultEventValues, start: dayjs(selectInfo.start)});
    setOpen(true);
  }
  const openEditForm = (event: EventImpl) => {
    console.log(event)
      if(event){
        const h = dayjs(event.end).diff(dayjs(event.start), 'h')
        const m = dayjs(event.end).diff(dayjs(event.start), 'minute')-h*60;
        console.log(dayjs(event.end).diff(dayjs(event.start), 'h'));
        console.log(dayjs(event.end).diff(dayjs(event.start), 'minute'));
        const duration = dayjs(`${h}:${m}`, 'HH:mm')
        setSelectedEvent({ 
          _id: event.extendedProps._id, 
          repetitorId: event.extendedProps.repetitorId, 
          price: event.extendedProps.price,
          studentId: event.extendedProps.studentId,
          groupId: event.groupId,
          students: event.extendedProps.students,
          title: event.title, 
          start: dayjs(event.start), 
          duration 
        });
      }
      else
      setSelectedEvent({ ...defaultEventValues});

      setOpen(true);
  }
    return (<div>
      <Title level={2}>Расписание</Title>
      <Profit />
      <div style={{ width: '100%', overflowY: 'auto' }}>
        <div style={{ minWidth:1024, }}><Calendar openEditForm={openEditForm} openAddForm={openAddForm} /></div>
      </div>
      <EventModalForm initial={selectedEvent} setOpen={setOpen} open={open} />
  </div>
)};

export default Schedule;

