import React, { useEffect, useMemo, useState } from 'react';
import { Empty, Transfer } from 'antd';
import type { TransferProps } from 'antd';
import { useSelector } from 'react-redux';
import { selectStudents } from '../../redux/studentsSlice';

interface RecordType {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
}

interface IGroupTransfer{
  setTargetKeys: (value: TransferProps['targetKeys']) => void;
  targetKeys: TransferProps['targetKeys']
}

const EmptyLocale: React.FC = () => {
  return <Empty description={"нет данных"} image={Empty.PRESENTED_IMAGE_SIMPLE} />
}

const GroupTransfer: React.FC<IGroupTransfer> = ({ setTargetKeys, targetKeys }) => {
  const [mockData, setMockData] = useState<RecordType[]>([]);
  const students = useSelector(selectStudents)
  const data = useMemo(() => students?.map(
    ({_id, name, description}) => ({
      key: _id, 
      title: name, 
      description: description ?? '', 
      chosen: false
    }))
  , [students])

  const filterOption = (inputValue: string, option: RecordType) =>
    option.description.indexOf(inputValue) > -1;

  const handleChange: TransferProps['onChange'] = (newTargetKeys) => {
    setTargetKeys(newTargetKeys);
  };

  const handleSearch: TransferProps['onSearch'] = (dir, value) => {
    console.log('search:', dir, value);
  };

  return (<Transfer
      dataSource={data}
      filterOption={filterOption}
      targetKeys={targetKeys}
      titles={['Ученики', 'Группа']}
      oneWay
      showSelectAll={false}
      onChange={handleChange}
      locale={
        { itemUnit: '', itemsUnit: '',  notFoundContent: <EmptyLocale /> }
      }
      render={(item) => item.title}
    />
  );
};

export default GroupTransfer;