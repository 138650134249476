import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import useGetStudents from '../../api/hooks/useGetStudents';
import StudentsTable from './studentsTable';
import useGetGroups from '../../api/hooks/useGetGroups';
import { UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import StudentModalForm from './studentModal';
import GroupModalForm from './groupForm';
import { Group, GroupFieldType, Student, StudentFieldType } from '../../types/studentsTypes';
import { useSelector } from 'react-redux';
import { selectGroups, selectStudents } from '../../redux/studentsSlice';
import dayjs, { Dayjs } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';

const { Title } = Typography;

const Students: React.FC = () => {
  const isLoading = useGetStudents();
  const isLoadingGroups = useGetGroups()
  const [open, setOpen] = useState<boolean>(false)
  const [openGroupForm, setOpenGroupForm] = useState<boolean>(false)
  const [selectedStudent, setSelectedStudent] = useState<StudentFieldType>()
  const [selectedGroup, setSelectedGroup] = useState<GroupFieldType>()
  const students = useSelector(selectStudents)
  const groups = useSelector(selectGroups)
  const defaultGroupValues: GroupFieldType = {
    name: '',
    price: undefined,
    active: true,
    _id: '',
    description: '',
    students: [],
  }
  const defaultStudentValues: StudentFieldType = {
    email: '',
    name: '',
    surname: '',
    price: undefined,
    active: true,
    groupId: '',
    _id: '',
    description: '',
  }

  const openEditForm = (isGroup: boolean = false, id: string | undefined = undefined) => {
    console.log(dayjs.duration({hours: 10, minutes: 20}))
    if(isGroup){
      
      if(id){
        const group = groups.find(item => item._id == id)
        if(group) {
          const duration: Dayjs | undefined = group.duration ? dayjs(`${group.duration.hours}:${group.duration.minutes}`, 'HH:mm') : undefined
          setSelectedGroup({ ...group, duration, students: students?.filter(item => item.groupId == id).map(({_id}) => _id)});
        }else
          setSelectedGroup(defaultGroupValues);
      }else{
        setSelectedGroup(defaultGroupValues);
      }
      setOpenGroupForm(true);
    }else{
      const student: Student | undefined = students.find(item => item._id == id)
      if(student){
        const duration: Dayjs | undefined = student.duration ? dayjs(`${student.duration.hours}:${student.duration.minutes}`, 'HH:mm') : undefined
        setSelectedStudent({...student, duration});
      }else setSelectedStudent(defaultStudentValues)

      setOpen(true);
    }
  }

  return (<div>
      <Title level={2}>Ученики</Title>
      <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
        <div style={{display: 'flex', gap: 20}}>
          <Button onClick={() => openEditForm()}><UserAddOutlined />Добавить Ученика</Button>
          <Button onClick={() => openEditForm(true)}><UsergroupAddOutlined />Добавить группу</Button>
        </div>
        <div style={{width: '100%', overflow: 'auto'}}><StudentsTable openEditForm={openEditForm}  /></div>
        <StudentModalForm setOpen={setOpen} initial={selectedStudent} open={open} />
        <GroupModalForm setOpen={setOpenGroupForm} initial={selectedGroup} open={openGroupForm} />
      </div>
  </div>
)};

export default Students;