import React, { useState } from 'react';
import { Button, Form, FormProps, message, Popover, Steps, StepsProps, theme } from 'antd';
import '../globalStyle.css';
import './style.css';

import Title from 'antd/es/typography/Title';
import RoleSelect from './roleSelect';
import { useNavigate } from 'react-router-dom';
import { FieldType } from './types';
import { useRegistarationMutation } from '../../api/services/auth';
import RegistrationForm from './registrationForm';
import { errorHandler, FetchError } from '../../utils/errorHandler';
import { setCredentials } from '../../redux/authSlice';
import { useDispatch } from 'react-redux';
import EmailActivation from './emailActivation';
import { useForm, useWatch } from 'antd/es/form/Form';



const Registration: React.FC = ({}) => {
    const [ role, setRole ] = useState<string>();
    const [ email, setEmail ] = useState<string>('');
  const [current, setCurrent] = useState(0);
  const [registaration, { isLoading }] = useRegistarationMutation()
  const dispatch = useDispatch()

 
    const onRoleSelect = (selectedRole: string) => {
        setRole(selectedRole)
        next()
    }
    const navigate = useNavigate()

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    console.log('Success:', values);
    try {
        const { confirmPassword, ...rest } = values
        const user = await registaration({ ...rest, role: role! }).unwrap()
        dispatch(setCredentials(user))
        setEmail(rest.email!);
        next()
    } catch (err) {
        console.log(err)
        errorHandler(err as FetchError)
    };
};

  const steps = [
    {

      description: "Роль",
      title: "Роль",

      content: <RoleSelect onRoleSelect={onRoleSelect} />,
    },
    {
      description: "Данные пользователя",

      title: "Данные пользователя",

    content: <RegistrationForm onFinish={onFinish} isLoading={isLoading} />,
    },
    {
      title: "Подтверждение e-mail",
      description: "Подтверждение e-mail",
      content: <EmailActivation email={email} />,
    },
  ];
  const items = steps.map((item) => ({ key: item.title, description: item.description, title: item.title }));

  const stepsStyle: React.CSSProperties = {
    marginLeft: -60, 
  };
const customDot: StepsProps['progressDot'] = (dot, {index, description}) => (
    <Popover
        content={
            <span>
                Шаг {index+1} {description}
            </span>
        }>
        {dot}
    </Popover>
);
  return (
    <>
        <Title level={2}>Регистрация</Title>
        <div>
            <Steps className={'hideTitle'} responsive={false} current={current} direction='horizontal' items={items} progressDot={customDot} style={stepsStyle} />
            {current > 0 && (
                <Button style={{ color: 'grey', margin: 0, padding: 0 }} type="link"  onClick={() => prev()}>
                    ← Назад
                </Button>
            )}
        </div>
         <div >{steps[current].content}</div>
        <Button type="link" onClick={() => navigate('/login')} className={'buttonSecond'}>
            Вход
        </Button> 
    </>
  );
};

export default Registration;