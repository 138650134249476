import { Group, Student, StudentsForTable } from "../types/studentsTypes";

export const getStudentsForTable = (students: Student[], groups: Group[]) => {
    const groupsForTable: StudentsForTable[] = groups.map((group) => {
      const children = students
        .filter(student => student.groupId === group._id)
        .map(({ name, _id, price, color }) => ({ name, key:_id,  price, color }))

      return ({
      name: group.name, 
      key: group._id,  
      price: group.price,
      color: group.color,
      children: children.length ? children : null,
      })
    });
    const restStudents: StudentsForTable[] = students
      .filter(student => !student.groupId)
      .map(({ name, _id, price, color }) => ({ name, key:_id,  price, color}))
    
    return groupsForTable.concat(restStudents)
  }