import React from 'react';
import type { FormProps } from 'antd';
import { Button, Form, Input } from 'antd';
import { useLoginMutation } from '../../api/services/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCredentials } from '../../redux/authSlice';
import { Typography } from 'antd';


import '../globalStyle.css';
import { errorHandler, FetchError } from '../../utils/errorHandler';
import { FieldType, IRegistrationForm } from './types';



const { Title } = Typography;
const RegistrationForm: React.FC<IRegistrationForm> = ({onFinish, isLoading}) => {
    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
      console.log('Failed:', errorInfo);
        
    };
    return (
            <Form
                name="basic"
                layout={'vertical'}
                className='formRegistration'
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                {
                    type: 'email',
                    message: 'Не соответствует формату e-mail',
                },
                {
                    required: true,
                    message: 'Введите e-mail',
                },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="name"
                label="Имя"
                rules={[{ required: true, message: 'Введите ваше имя', whitespace: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="surname"
                label="Фамилия"
            >
                <Input />
            </Form.Item>
            <Form.Item<FieldType>
                label="Пароль"
                name="password"
                hasFeedback
                rules={[{ required: true, message: 'Введите пароль' }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                label="Подтвердите пароль"
                dependencies={['password']}
                hasFeedback
                rules={[
                {
                    required: true,
                    message: 'Повторите пароль',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Пароли не совпадают'));
                    },
                }),
                ]}
            >
                <Input.Password />
            </Form.Item>
            {/* <Form.Item<FieldType>
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
            >
            <Checkbox>Remember me</Checkbox>
            </Form.Item> */}
            <div>
                <Form.Item >
                    <Button type="primary" htmlType="submit" loading={isLoading} className={'button'}>
                        Зарегистрироваться
                    </Button>
                </Form.Item>

            </div>
        </Form>
)};

export default RegistrationForm;