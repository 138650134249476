import React, { useMemo } from 'react';
import { Form, Select } from 'antd';
import { useSelector } from 'react-redux';

import { selectStudents } from '../../redux/studentsSlice';
import dayjs, { Dayjs } from 'dayjs';

const StudentsSelect: React.FC = () => {
  const students = useSelector(selectStudents)
  const studentOptions = useMemo(() => students.map(({ _id, name, price, duration })=> ({ value: _id, label: name, price, duration })), [students])
  const form = Form.useFormInstance();
  const onChange = (_: any, {price, duration}: any) => {
      const durationDayjs: Dayjs | undefined = duration ? dayjs(`${duration.hours}:${duration.minutes}`, 'HH:mm') : undefined
      form.setFieldsValue({ price, duration: durationDayjs })
  }
  return ( 
  <Form.Item
    name="studentId"
    label="Выберите Ученика"
  >
    <Select
      options={studentOptions}
      onChange={onChange}
    />
  </Form.Item>
)};

export default StudentsSelect;