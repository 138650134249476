import { Statistic } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectEvents } from '../../redux/eventsSlice';


const Profit: React.FC = () => {
  const events = useSelector(selectEvents)
  const priceSum = useMemo(() => events.reduce((acc, item) => acc + (item.price ?? 0), 0), [events])

  return ( <div style={{marginBottom: 20}}><Statistic title="Прибыль за период" value={priceSum} suffix="₽" /></div>)};

export default Profit;