import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { errorHandler, FetchError } from "../../utils/errorHandler"
import { useGetCurrentUserQuery } from "../services/auth"
import { setUserProfile } from "../../redux/authSlice"

const useGetCurrentUser = () => {
  const dispatch = useDispatch();
  const { data, error, isLoading } = useGetCurrentUserQuery()
        
  useEffect(() => {
    if(error){
      errorHandler(error as FetchError)
    }else if(data){
      dispatch(setUserProfile(data))
    }
  }, [error, data])

    return isLoading;
}

export default useGetCurrentUser;