import {combineReducers, configureStore} from "@reduxjs/toolkit"
import authSlice from "./authSlice"
import cartSlice from "./cartSlice"
import errorSlice from "./errorSlice"
import { api } from "../api/services/auth"
import { apiStudents } from "../api/services/students"
import studentsSlice from "./studentsSlice"
import eventsSlice from "./eventsSlice"
import { apiEvents } from "../api/services/events"

const rootReducer = combineReducers(
    {
        [api.reducerPath]: api.reducer,
        [apiStudents.reducerPath]: apiStudents.reducer,
        [apiEvents.reducerPath]: apiEvents.reducer,
        auth:authSlice,
        students: studentsSlice,
        events: eventsSlice,

        cart:cartSlice,
        error:errorSlice
    })

const store = configureStore(
    {
        reducer:rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(api.middleware)
        .concat(apiStudents.middleware)
        .concat(apiEvents.middleware),
    }
)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store