import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../redux'
import { GroupAddRequest, GroupEditRequest, GroupEditResponse, GroupResponse, GroupsResponse, StudentAddRequest, StudentEditRequest, StudentResponse, StudentsResponse } from '../../types/studentsTypes'



export const apiStudents = createApi({
  reducerPath: 'apiStudents',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://learnzone.ru/api_lz/',
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    studentAdd: builder.mutation<StudentResponse, StudentAddRequest>({
      query: (credentials) => ({
        url: 'student/add',
        method: 'POST',
        body: credentials,
      }),
    }),
    studentEdit: builder.mutation<StudentResponse, StudentEditRequest>({
      query: (credentials) => ({
        url: 'student/edit',
        method: 'PUT',
        body: credentials,
      }),
    }),
    getStudents: builder.query<StudentsResponse, void>({
      query: () => 'students',
    }),
    groupAdd: builder.mutation<GroupEditResponse, GroupAddRequest>({
      query: (credentials) => ({
        url: 'group/add',
        method: 'POST',
        body: credentials,
      }),
    }),
    groupEdit: builder.mutation<GroupEditResponse, GroupEditRequest>({
      query: (credentials) => ({
        url: 'group/edit',
        method: 'PUT',
        body: credentials,
      }),
    }),
    getGroups: builder.query<GroupsResponse, void>({
      query: () => 'groups',
    }),
  }),
})

export const { useGetStudentsQuery, useGetGroupsQuery, useGroupAddMutation, useGroupEditMutation, useStudentAddMutation, useStudentEditMutation } = apiStudents
