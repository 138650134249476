import React, { useState } from 'react';
import { Button, Layout, Menu, MenuProps, theme } from 'antd';
import { navigation } from './navigation';
import { useNavigate } from 'react-router-dom';
interface IHeaderProps{
  setCollapsed: (collapsed: boolean) => void;
  collapsed: boolean;
  isMobile: boolean;
}
const MenuHeader: React.FC<IHeaderProps> = ({setCollapsed, isMobile}) => {
  const navigate = useNavigate();
  const onClick: MenuProps['onClick'] = (e) => {
    navigate('/' + e.key)
    if(isMobile)
      setCollapsed(true)
  };
  return <Menu
    theme="light"
    mode="inline"
    onClick={onClick}
    defaultSelectedKeys={['1']}
    items={ navigation }
    style={ {height: "100%", zIndex: 100} }
  />
};

export default MenuHeader;