import React, { useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu, theme } from 'antd';
import { navigation } from './navigation';
import UserMenu from './userMenu';
import HeaderLayout from './Header';
import MenuHeader from './menuHeader';
import Cookies from 'js-cookie';
import { setToken } from '../../redux/authSlice';
import { useDispatch } from 'react-redux';

const { Header, Sider, Content } = Layout;

const LayoutFull: React.FC<{children: React.ReactElement, height: number}> = ({children, height}) => {

  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const siderStyle: React.CSSProperties = {
    overflow: 'auto',
    position: 'fixed',
    insetInlineStart: 0,
    zIndex: 100,
    top: 0,
    bottom: 0,
    scrollbarWidth: 'thin',
    scrollbarGutter: 'stable',
  };
 
  return (
    <Layout >
      <Sider theme={'light'} trigger={null} collapsible collapsed={collapsed} style={siderStyle}>
        <div className="demo-logo-vertical" />
        <MenuHeader setCollapsed={setCollapsed} collapsed={collapsed} isMobile={true} />
      </Sider>
      <Layout style={{ marginInlineStart: collapsed ? 80 : 200 }}>
      <HeaderLayout setCollapsed={setCollapsed} collapsed={collapsed}  />
        <Content
          style={{
            margin: '24px 16px 24px 16px',
            padding: 24,
            height: height - 112,
            overflow: 'auto',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutFull;