import { useSelector } from "react-redux";
import { selectStudents, selectGroups } from "../../redux/studentsSlice";
import { Badge, Button, Table, TableProps } from "antd";
import { Group, Student, StudentsForTable } from "../../types/studentsTypes";
import { useMemo } from "react";
import { getStudentsForTable } from "../../utils/studentsUtils";
import { EditOutlined } from '@ant-design/icons';

interface IStudentsTableProps{
  openEditForm: (isGroup: boolean, id: string) => void;
}

const StudentsTable: React.FC<IStudentsTableProps> = ({openEditForm}) => {
  const students = useSelector(selectStudents)
  const groups = useSelector(selectGroups)

  const studentsForTable: StudentsForTable[] = useMemo(() => getStudentsForTable(students, groups), [ students, groups ])

  const columns: TableProps<StudentsForTable>['columns'] = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => {
          return <div>{record.name} {record.children !== undefined 
            ? <Badge style={{marginRight: 10, marginLeft: 10}} count={record.children ? record.children.length : 0} showZero color={record.color ?? 'gray'} />
            : <Badge style={{marginRight: 10, marginLeft: 10}} color={record.color} />
            }</div>
      }
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: '',
      key: "action",
      render: (_, record) => {
        console.log('record', record)
          return <div><Button onClick={() => openEditForm(record.children !== undefined, record.key )}><EditOutlined /></Button></div>
      }
    },
  ]
  return (<Table<StudentsForTable>
    style={{minWidth: 500}}
        columns={columns}
        dataSource={studentsForTable}
      />
)};

export default StudentsTable;