import React from 'react';
import { Button } from 'antd';
import { IRoleSelectRole } from './types';

import './style.css'

const RoleSelect: React.FC<IRoleSelectRole> = ({onRoleSelect}) => {
  return (
    <div className='roleSelectContainer'>
      <Button type="primary" size={'large'} onClick={() => onRoleSelect('repetitor')}>
          Я репетитор
      </Button>
      <Button type="primary" size={'large'} onClick={() => onRoleSelect('student')}>
          Я ученик
      </Button>
    </div>
  );
};

export default RoleSelect;