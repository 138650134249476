import React, { useEffect, useMemo, useState } from 'react';
import { Button, ColorPicker, Form, FormProps, Input, InputNumber, Modal, Segmented, Select, Space, TimePicker, Tooltip, Typography } from 'antd';
import { useGetStudentsQuery, useStudentAddMutation, useStudentEditMutation } from '../../api/services/students';
import { errorHandler, FetchError } from '../../utils/errorHandler';
import { selectGroups, selectStudents, setStudents, studentAddToList, studentEditOnList } from '../../redux/studentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { StudentFieldType, Student } from '../../types/studentsTypes';

const { Title } = Typography;

interface IUserModalForm{
  open: boolean;
  setOpen: (value: boolean) => void;
  initial?: StudentFieldType;
}

const StudentModalForm: React.FC<IUserModalForm> = ({ setOpen, open, initial }) => {

  const [form] = Form.useForm();
  const [studentAdd, resultAdd] = useStudentAddMutation()
  const [studentEdit, resultEdit] = useStudentEditMutation()
  const isLoading = resultAdd.isLoading || resultEdit.isLoading;
  const [isGroup, setIsGroup] = useState<string>(initial?.groupId ? 'addToGroup' : 'personal')
  useEffect(() => {
    if(form) form.setFieldsValue(initial)
    setIsGroup(initial?.groupId ? 'addToGroup' : 'personal')
   }, [form, initial])
  const closeModal = () => setOpen(false)
  const dispatch = useDispatch()
  const groups = useSelector(selectGroups)
  const isGroupOptions = [
    { label: 'Индивидуальные занятия', value: 'personal', icon: <UserOutlined /> },
    { label: 'Добавить в группу', value: 'addToGroup', icon: <UsergroupAddOutlined /> },
  ]
  const groupOptions = useMemo(() => groups.map(({ _id, name })=> ({ value: _id, label: name })), [groups])
  const onFinish: FormProps<StudentFieldType>['onFinish'] = async (values) => {
    try {
        const duration = values.duration ? {hours: values.duration.hour(), minutes: values.duration.minute()} : null
        if(!initial?._id){
          const {color, ...restValues} = values;
          console.log(typeof color)
          const hexColor =  typeof color === 'string' ? color : color?.toHexString()

          const {student} = await studentAdd({ ...restValues, color: hexColor, duration }).unwrap()

          dispatch(studentAddToList(student))
        }else{
          const {color, groupId, groupPrice, ...restValues} = values;
          const hexColor =  typeof color === 'string' ? color : color?.toHexString()

          const {student} = await studentEdit({
            ...restValues, 
            _id: initial._id, 
            color: hexColor,
            groupId: groupId ?? undefined,
            groupPrice: groupPrice ?? undefined,
            duration,
          }).unwrap()

          dispatch(studentEditOnList(student))
        }
        closeModal()
    } catch (err) {
        console.log(err)
        errorHandler(err as FetchError)
    };
};
  const handleOk = () => {
    form.submit()
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  return (<Modal
    title={initial?._id ? "Изменить ученика" : "Добавить ученика"}
    open={open}
    onOk={handleOk}
    confirmLoading={false}
    onCancel={handleCancel}
    footer={[
      <Button key="back" onClick={handleCancel}>
        Отмена
      </Button>,
      <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
        Сохранить
      </Button>,
    ]}
  >
    <div style={{ paddingTop:20, paddingBottom: 20 }}>
    <Form
                name="basic"
                layout={'vertical'}
                form={form}
                className='formRegistration'
                
                onFinish={onFinish}
                autoComplete="off"
                
            >
            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                {
                    type: 'email',
                    message: 'Не соответствует формату e-mail',
                }
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="name"
                label="Имя"
                rules={[{ required: true, message: 'Введите ваше имя', whitespace: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="surname"
                label="Фамилия"
            >
                <Input />
            </Form.Item>
            <Form.Item
              name="duration"
              label="Продолжительность индивидуального занятия"
            >
              <TimePicker showNow={false} placeholder={'Продолжительность'} format={'HH:mm'} minuteStep={15} allowClear={false} />
            </Form.Item>
            <Form.Item
              name="price"
              label="Цена индивидуального занятия"
              rules={[
                { type: "integer", message: "Только число" },
                {
                  type: 'number',
                  min: 0,
                  message: "Не может быть отрицательной"
                }
              ]}
              validateFirst
            >
              <InputNumber addonAfter="₽" />
            </Form.Item>
      <Segmented<string>
          style={{marginBottom: 20, maxWidth: '100%'}}
          options={isGroupOptions}
          value={isGroup}
          onChange={ (value) => {
            form.setFieldsValue({groupId: undefined, groupPrice: undefined})
            setIsGroup(value)
          } }
        />
         
      <Form.Item
          name="groupId"
          label="Выберите группу"
          hidden={isGroup !== 'addToGroup'}
        >
          <Select
            options={groupOptions}
          />
        </Form.Item>
        <Form.Item
          name="groupPrice"
          hidden={isGroup !== 'addToGroup'}
          label="Персональная цена занятия в группе"
          rules={[
            { type: "integer", message: "Только число" },
              {
                type: 'number',
                min: 0,
                message: "Не может быть отрицательной"
              }
          ]}
          validateFirst
      >
          <InputNumber addonAfter="₽" />
      </Form.Item>
      <Form.Item
          name="color"
          label="Выберите цвет (будет отображаться в расписании)"
        >
          <ColorPicker format={"hex"}           onChange={(color) => console.log(color)}
 />
        </Form.Item>
        
        </Form>
        </div>
  </Modal>
)};

export default StudentModalForm;