import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, FormProps, Input, InputNumber, Modal, Segmented, Space, TimePicker, Typography } from 'antd';
import { errorHandler, FetchError } from '../../utils/errorHandler';
import { useDispatch, useSelector } from 'react-redux';
import { useEventAddMutation, useEventEditMutation } from '../../api/services/events';
import { eventAddToList, eventEditOnList } from '../../redux/eventsSlice';
import { UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';

import { Event, FieldType, SelectedEvent } from '../../types/eventsTypes';
import ru from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
import { selectGroups, selectStudents } from '../../redux/studentsSlice';
import StudentsSelect from './studentsSelect';
import GroupSelect from './groupSelect';

import './style.css'

dayjs.extend(duration)
const ruLocale: typeof ru = {
  ...ru,
  lang: {
    ...ru.lang,
    fieldDateFormat: 'YYYY-MM-DD',
    fieldDateTimeFormat: 'YYYY-MM-DD HH:mm',
    yearFormat: 'YYYY',
    cellYearFormat: 'YYYY',
  },
};

const { Title } = Typography;

interface IUserModalForm{
  open: boolean;
  setOpen: (value: boolean) => void;
  initial?: SelectedEvent;
}

const lessonTypes = [
  { label: 'Индивидуальное', value: 'personal', icon: <UserOutlined /> },
  { label: 'Групповое', value: 'group', icon: <UsergroupAddOutlined /> },
  { label: 'Произвольное', value: 'any', icon: <UsergroupAddOutlined /> },
]

const EventModalForm: React.FC<IUserModalForm> = ({ setOpen, open, initial }) => {

  const [form] = Form.useForm();
  const [lessonType, setLessonType] = useState<string>('personal');
  const [eventAdd, resultAdd] = useEventAddMutation()
  const [eventEdit, resultEdit] = useEventEditMutation()
  const isLoading = resultAdd.isLoading || resultEdit.isLoading;

  useEffect(() => {
    console.log('initial', initial)
    if(form) form.setFieldsValue(initial)
   }, [form, initial])
  const closeModal = () => setOpen(false)
  const dispatch = useDispatch()
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    console.log(values.duration.hour())
    console.log(values.duration.minute())
    const start: string = values.start.toISOString()
    const end: string = values.start.add(dayjs.duration({hours: values.duration.hour(), minutes: values.duration.minute()})).toISOString()
    console.log(start, end)
    try {
        if(!initial?._id){
          const {...restValues} = values;
          const { event } = await eventAdd({
            ...restValues, 
            start,
            end
          }).unwrap()
          dispatch(eventAddToList(event))
        }else{
          const { groupId, studentId, students, ...restValues} = values;
          const { event } = await eventEdit({ 
            _id: initial._id, 
            ...restValues,
            groupId,
            studentId,
            students,
            start,
            end
          }).unwrap()
          dispatch(eventEditOnList(event))
        }
        closeModal()
    } catch (err) {
        console.log(err)
        errorHandler(err as FetchError)
    };
};
  const handleOk = () => {
    form.submit()
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  return (<Modal
    title={initial?._id ? "Изменить запись" : "Добавить запись"}
    open={open}
    onOk={handleOk}
    confirmLoading={false}
    onCancel={handleCancel}
    footer={[
      <Button key="back" onClick={handleCancel}>
        Отмена
      </Button>,
      <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
        Сохранить
      </Button>,
    ]}
  >
    <div style={{ paddingTop:20, paddingBottom: 20 }}>
      <Form
        name="basic"
        layout={'vertical'}
        form={form}
        className='formRegistration'
        onFinish={onFinish}
        autoComplete="off"
      >
        <Segmented<string>
          style={{marginBottom: 20}}
          options={lessonTypes}
          block={true}
          value={lessonType}
          onChange={ (value) => {
            setLessonType(value)
            form.setFieldsValue({ groupId: undefined, studentId: undefined, students: undefined, price: undefined })
          } }
        />
        {lessonType === 'personal' && <StudentsSelect />}
        {lessonType === 'group' && <GroupSelect />}
        <div className='space'>
          <Form.Item
            name="start"
            label="Дата и время начала занятия"
          >
            <DatePicker
              showTime
              locale={ruLocale}
              allowClear={false}
              minuteStep={15}
            />
          </Form.Item>
          <Form.Item
            name="duration"
            label="Продолжительность занятия"
            rules={[{ required: true, message: 'Поле обязательно'}]}
          >
            <TimePicker showNow={false} format={'HH:mm'} locale={ruLocale} minuteStep={15} allowClear={false} />
          </Form.Item>
        </div>
        <Form.Item
          name="price"
          label="Цена занятия"
          rules={[
            { type: "integer", message: "Только число" },
            {
              type: 'number',
              min: 0,
              message: "Не может быть отрицательной"
            }
          ]}
        >
          <InputNumber addonAfter="₽"  />
        </Form.Item>
      </Form>
    </div>
  </Modal>
)};

export default EventModalForm;