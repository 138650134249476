import { useEffect } from "react"
import { useGetStudentsQuery } from "../services/students"
import { useDispatch } from "react-redux"
import { errorHandler, FetchError } from "../../utils/errorHandler"
import { setStudents } from "../../redux/studentsSlice"

const useGetStudents = () => {
    const { data, error, isLoading } = useGetStudentsQuery()
    const dispatch = useDispatch()
  
    useEffect(() => {
      if(error){
        errorHandler(error as FetchError)
      }else if(data){
        dispatch(setStudents(data))
      }
    }, [error, data])

    return isLoading;
}

export default useGetStudents;