import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { errorHandler, FetchError } from "../../utils/errorHandler"
import { useGetEventsQuery } from "../services/events"
import { setEvents } from "../../redux/eventsSlice"

const useGetEvents = (filter: {startDate?: string, endDate?: string}) => {
    const { data, error, isLoading } = useGetEventsQuery(filter)
    const dispatch = useDispatch()
  
    useEffect(() => {
      if(error){
        errorHandler(error as FetchError)
      }else if(data){
        dispatch(setEvents(data))
      }
    }, [error, data])

    return isLoading;
}

export default useGetEvents;