import React, { useEffect, useState } from 'react';
import { Button, ColorPicker, Form, FormProps, Input, InputNumber, Modal, TimePicker, TransferProps, Typography } from 'antd';
import { useGroupAddMutation, useGroupEditMutation, useStudentAddMutation } from '../../api/services/students';
import { errorHandler, FetchError } from '../../utils/errorHandler';
import { groupAddToList, groupEditOnList, studentAddToList } from '../../redux/studentsSlice';
import { useDispatch } from 'react-redux';
import GroupTransfer from './groupTransfer';
import { GroupFieldType } from '../../types/studentsTypes';

const { Title } = Typography;

interface IUserModalForm{
  open: boolean;
  setOpen: (value: boolean) => void;
  initial?: GroupFieldType;

}

const GroupModalForm: React.FC<IUserModalForm> = ({ setOpen, open, initial }) => {
  const [targetKeys, setTargetKeys] = useState<TransferProps['targetKeys']>([]);

  const [form] = Form.useForm();
  const [groupAdd, resultAdd] = useGroupAddMutation()
  const [groupEdit, resultEdit] = useGroupEditMutation()
  const isLoading = resultAdd.isLoading || resultEdit.isLoading;

  const closeModal = () => setOpen(false)
  const dispatch = useDispatch()
  
  useEffect(() => {
    if(form) form.setFieldsValue(initial)
    if(initial?.students)setTargetKeys(initial.students)
   }, [form, initial])

  const onFinish: FormProps<GroupFieldType>['onFinish'] = async (values) => {
    try {
        const {color, ...restValues} = values
        const hexColor =  typeof color === 'string' ? color : color?.toHexString()
        const duration = values.duration ? {hours: values.duration.hour(), minutes: values.duration.minute()} : null

        if(!initial?._id){
          const resp = await groupAdd({ ...restValues, duration, color: hexColor, students: targetKeys?.map(item => item.toString()) || []}).unwrap()
          dispatch(groupAddToList(resp))
        }else{
          const resp = await groupEdit({ ...restValues, duration, color: hexColor, _id: initial?._id, students: targetKeys?.map(item => item.toString()) || []}).unwrap()
          dispatch(groupEditOnList(resp))
        }
        closeModal()
    } catch (err) {
        console.log(err)
        errorHandler(err as FetchError)
    };
};
  const handleOk = () => {
    form.submit()
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  return (<Modal
    title={initial?._id ? "Изменить группу" : "Добавить группу"}
    open={open}
    onOk={handleOk}
    confirmLoading={false}
    onCancel={handleCancel}
    footer={[
      <Button key="back" onClick={handleCancel}>
        Отмена
      </Button>,
      <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
        Сохранить
      </Button>,
    ]}
  >
    <div style={{ display:'flex', flexDirection: 'column', gap: 40, paddingTop:20, paddingBottom: 20 }}>
    <Form
        name="basic"
        layout={'vertical'}
        form={form}
        className='formRegistration'
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
    >
      <Form.Item
          name="name"
          label="Имя"
          rules={[{ required: true, message: 'Введите ваше имя', whitespace: true }]}
      >
          <Input />
      </Form.Item>
      <Form.Item
          name="description"
          label="Описание"
      >
          <Input />
      </Form.Item>
      <Form.Item
        name="duration"
        label="Продолжительность групового занятия"
      >
        <TimePicker showNow={false} placeholder={'Продолжительность'} format={'HH:mm'} minuteStep={15} allowClear={false} />
      </Form.Item>
      <Form.Item
          name="price"
          label="Цена одного занятия в группе"
          
          rules={[
            { required: true, message: 'Введите цену одного занятия в группе' },
            { type: "integer", message: "Только число" },
              {
                type: 'number',
                min: 0,
                message: "Не может быть отрицательной"
              }
          ]}
          validateFirst
      >
          <InputNumber addonAfter="₽" />
      </Form.Item>
      <Form.Item
          name="color"
          label="Выберите цвет (будет отображаться в расписании)"
        >
          <ColorPicker format={"hex"} />
        </Form.Item>
    </Form>
    <GroupTransfer setTargetKeys={setTargetKeys} targetKeys={targetKeys} />
    </div>
  </Modal>
)};

export default GroupModalForm;