import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Form, FormInstance, Select } from 'antd';
import { useSelector } from 'react-redux';

import { selectGroups, selectStudents } from '../../redux/studentsSlice';
import { Student } from '../../types/studentsTypes';
import dayjs, { Dayjs } from 'dayjs';

const GroupSelect: React.FC = () => {
  const [groupPrice, setGroupPrice]  = useState<number>()
  const groups = useSelector(selectGroups)
  const students = useSelector(selectStudents)
  const groupOptions = useMemo(() => groups.map(({ _id, name, price, duration })=> ({ value: _id, label: name, price, duration })), [groups])
  const form = Form.useFormInstance();
  const groupId = Form.useWatch('groupId', form);
  const selectedStudents = Form.useWatch('students', form);

  const studentsList: Student[] = useMemo(() => students.filter(item => item.groupId === groupId), [groupId])
  const onChange = (_: any, {price, duration}: any) => {
    setGroupPrice(price)
    const durationDayjs: Dayjs | undefined = duration ? dayjs(`${duration.hours}:${duration.minutes}`, 'HH:mm') : undefined
    form.setFieldsValue({ duration: durationDayjs })
}
  useEffect(() => {
    form.setFieldValue('students', studentsList.map(item => item._id))
  }, [studentsList])
  useEffect(() => {
    if(groupPrice) {
      console.log(selectedStudents)
      const price = studentsList.reduce<number>((acc, student) => {
        if(selectedStudents?.includes(student._id))
          return student.groupPrice ? acc + student.groupPrice : acc + groupPrice;
        else
          return acc
      }, 0)
      form.setFieldsValue({ price })
    }
  }, [studentsList, groupPrice, selectedStudents])

  return ( <>
  <Form.Item
    name="groupId"
    label="Выберите группу"
  >
    <Select
      options={groupOptions}
      onChange={onChange}
    />
  </Form.Item>
  {groupId && <Form.Item
    name="students"
    label="Выберите присутствующих учеников"
  >
    <Checkbox.Group style={{ display: 'flex', flexDirection: 'column', gap:10 }}>
      { studentsList.map(student => <Checkbox key={student._id} value={student._id}>{student.surname} {student.name}</Checkbox>) }
    </Checkbox.Group>
</Form.Item>}
</>)};

export default GroupSelect;