import React from 'react';
import type { FormProps } from 'antd';
import { Button, Form, Input, Spin } from 'antd';
import { useActivationMutation, useLoginMutation } from '../../api/services/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCredentials } from '../../redux/authSlice';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import '../globalStyle.css';
import { errorHandler, FetchError } from '../../utils/errorHandler';
import { FieldType, IEmailActivation, IRegistrationForm } from './types';
import { OTPProps } from 'antd/es/input/OTP';



const { Title } = Typography;
const EmailActivation: React.FC<IEmailActivation> = ({ email }) => {
    const [activation, { isLoading }] = useActivationMutation()
    const navigate = useNavigate()

    const onChange: OTPProps['onChange'] = async (code) => {

        try {
            await activation({ code }).unwrap()
            navigate('/')
        } catch (err) {
            console.log(err)
            errorHandler(err as FetchError)
        };
    }
    const repeatActivationCode = () => {
        console.log('repeat')
    }
    return (
        <div className={'roleSelectContainer'}>
            <div>На e-mail <b>{email}</b> отправлен код для подтверждения почты. <br /><br />Пожалуйста введите его ниже:</div>
            <div style={{textAlign: 'center'}}>
                {!isLoading && <Input.OTP length={5} style={{columnGap: 20}} onChange={onChange} />}
                {isLoading && <Spin size="large" indicator={<LoadingOutlined spin />} />}
                </div>
            <div><Button type="link" onClick={repeatActivationCode} className={'buttonSecond'}>Отправить код повторно</Button></div>
        </div>
)};

export default EmailActivation;