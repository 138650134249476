import React from 'react';
import type { FormProps } from 'antd';
import { Button, Form, Input } from 'antd';
import { useLoginMutation } from '../../api/services/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setCredentials } from '../../redux/authSlice';
import { Typography } from 'antd';


import '../globalStyle.css';
import { errorHandler, FetchError } from '../../utils/errorHandler';
import { FieldType } from './types';



const { Title } = Typography;
const Login: React.FC = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let { from } = useParams();
    const [login, { isLoading }] = useLoginMutation()
    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        console.log('Success:', values);
        
        try {
            const user = await login(values).unwrap()
            dispatch(setCredentials(user))
            navigate(from ? '/' + from : '/schedule')
        } catch (err) {
            console.log(err)
            errorHandler(err as FetchError)
        };
    };
    
    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
      console.log('Failed:', errorInfo);

    };
    return (<>
            <Title level={2}>Авторизация</Title>
            <Form
                name="basic"
                layout={'vertical'}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
            <Form.Item<FieldType>
                label="Логин"
                name="username"
                rules={[{ required: true, message: 'Введите логин!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item<FieldType>
                label="Пароль"
                name="password"
                rules={[{ required: true, message: 'Введите пароль' }]}
            >
                <Input.Password />
            </Form.Item>

            {/* <Form.Item<FieldType>
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
            >
            <Checkbox>Remember me</Checkbox>
            </Form.Item> */}
            <div>
                <Form.Item >
                    <Button type="primary" htmlType="submit" loading={isLoading} className={'button'}>
                        Войти
                    </Button>
                </Form.Item>
                <div className="secondButtons">
                    <Button type="link" onClick={() => navigate('/password_restore')} className={'buttonSecond'}>
                        Забыли пароль?
                    </Button> 
                    <Button type="link" onClick={() => navigate('/registration')} className={'buttonSecond'}>
                        Регистрация
                    </Button>
                </div>
            </div>
        </Form>
    </>
)};

export default Login;