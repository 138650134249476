import { Divider, notification } from 'antd';
import Cookies from 'js-cookie';
import { redirect } from 'react-router-dom';


export interface FetchError{
    status: number | string,
    data?:
    {
        status: string,
        message: string,
        errors?: {
            errors: {
                "value": string,
                "msg": string,
                "param": string,
                "location": string
            }[]
        }
    },
    error?: string
}

const getErrorMessage = (error: FetchError): React.ReactElement => {
    //console.log(error.data?.msg)
   
    if(error.data?.errors?.errors?.length){
        const errors = error.data?.errors?.errors
        return <>{errors.map((item, i) => <div>{item.msg} { i < errors.length-1 ? <Divider /> : ''}</div>)}</>
    }else if(error.data?.message)
        return <>{error.data?.message}</>
    else
        return <></>
}

export const errorHandler = (error: FetchError) => {
    if(error.status === 401 && window.location.pathname.indexOf('login') !== -1) notification.error({message:"Неправильный логин или пароль", showProgress: true,
        pauseOnHover: true,});
    else if(error.status === 401) {
        let params = new URLSearchParams();
        params.set("from", window.location.pathname);
        Cookies.remove('accessToken', { path: '' })
        return redirect("/login?" + params.toString());
    }
    else if( error.status === 400 ) notification.error({message:"Возникла ошибка", description: getErrorMessage(error) ,showProgress: true,
        pauseOnHover: true,});
    else if(error.status === "FETCH_ERROR") notification.error({message:"Ошибка сети", showProgress: true,
        pauseOnHover: true,});
    else notification.error({message:"Возникла ошибка", description: JSON.stringify(error), showProgress: true,
        pauseOnHover: true,}); 
}