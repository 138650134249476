import React from 'react';
import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, LoaderFunctionArgs, redirect } from 'react-router-dom';
import Layout from './Layout';
import Cookies from "js-cookie";
import Login from '../pages/Login';
import Registration from '../pages/Registration';
import Schedule from '../pages/Schedule';
import Students from '../pages/Students';
import Dashboard from '../pages/Dashboard';


function protectedLoader({ request }: LoaderFunctionArgs) {
  const accessToken = Cookies.get("accessToken")
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  if (!accessToken) {
    let params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  return null;
}

async function loginLoader() {
  const accessToken = Cookies.get("accessToken")
  if (accessToken) {
    return redirect("/");
  }
  return null;
}

export const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    loader() {
      // Our root route always provides the user, if logged in
      return { user: "" };
    },
    Component: Layout,
    children: [
      {
        path: "login",
        loader: loginLoader,
        Component: () => <Login />,
      },
      {
        path: "registration",
        loader: loginLoader,
        Component: () => <Registration />,
      },
      {
        index: true,
        path: "schedule",
        loader: protectedLoader,
        Component: () => <Schedule />,
      },
      {
        path: "dashboard",
        loader: protectedLoader,
        Component: () => <Dashboard />,
      },
      {
        path: "students",
        loader: protectedLoader,
        Component: () => <Students />,
      },
      {
        path: "protected",
        loader: protectedLoader,
        Component: () => <>Protected Page</>,
      },
    ],
  },
]);


