import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from "../redux"
import {router} from "./router"

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
    </Provider>
  );
}

export default App;
