import {
  UserOutlined,
  CalendarOutlined,
  BarChartOutlined,
} from '@ant-design/icons';


export const navigation = [
  {
    key: 'dashboard',
    icon: <BarChartOutlined />,
    label: 'Дашборд',
  },
  {
    key: 'schedule',
    icon: <CalendarOutlined />,
    label: 'Расписание',
  },
  {
    key: 'students',
    icon: <UserOutlined />,
    label: 'Ученики',
  },
]